@charset "UTF-8";
/* 共通カルーセル用ドットアニメーション */
@keyframes dotsanime {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.clearText {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.elm_effect {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 500ms; }
  .elm_effect.active {
    opacity: 1;
    transform: translate(0, 0); }

.h2_box {
  padding: calc((113 / 750) * 100vw) calc((40 / 750) * 100vw); }
  .h2_box em, .h2_box span {
    color: white;
    display: block;
    font-weight: normal; }
  .h2_box em {
    font-size: calc((52 / 750) * 100vw);
    font-family: 'Open Sans', sans-serif; }
    .h2_box em span {
      font-size: calc((36 / 750) * 100vw); }
  .h2_box > span {
    font-size: calc((28 / 750) * 100vw); }
  .h2_box_top {
    background-image: url("../img/sp_business_top_jtb_bg1.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover; }
  .h2_box_tourism {
    background: url("../img/sp_business_tourism_jtb_bg.png") no-repeat left top;
    background-size: cover; }
  .h2_box_business {
    background: url("../img/sp_business_business_solution_bg.png") no-repeat left top;
    background-size: cover; }
  .h2_box_area {
    background: url("../img/sp_business_area_solution_jtb_bg.png") no-repeat left top;
    background-size: cover; }
  .h2_box_global {
    background: url("../img/sp_business_global_jtb_bg.png") no-repeat left top;
    background-size: cover; }

.h2_txt_top em, .h2_txt_top span {
  color: white;
  display: block;
  font-weight: normal; }

.h2_txt_top em {
  font-size: calc((52 / 750) * 100vw); }
  .h2_txt_top em span {
    font-size: calc((36 / 750) * 100vw); }

.h2_txt_top > span {
  font-size: calc((28 / 750) * 100vw);
  padding-top: calc((10 / 750) * 100vw); }

#domains_top .h3_domains_first {
  padding: calc((70 / 750) * 100vw) 0 0; }

#domains_top .h3_domains {
  padding: calc((100 / 750) * 100vw) 0 0; }

#domains_top .h3_domains_first,
#domains_top .h3_domains {
  font-size: calc((40 / 750) * 100vw);
  text-align: center;
  font-weight: bold;
  margin-bottom: calc((40 / 750) * 100vw); }

#domains_top .h4_box {
  position: relative; }

#domains_top .h4_main {
  padding: calc((70 / 750) * 100vw) 0 calc((200 / 750) * 100vw) calc((40 / 750) * 100vw);
  margin-right: calc((70 / 750) * 100vw); }
  #domains_top .h4_main em, #domains_top .h4_main span {
    color: white;
    display: block; }
  #domains_top .h4_main em {
    font-size: calc((40 / 750) * 100vw);
    font-weight: bold;
    line-height: 1.6; }
  #domains_top .h4_main span {
    font-size: calc((26 / 750) * 100vw);
    font-family: 'Open Sans', sans-serif;
    margin-top: calc((10 / 750) * 100vw); }

#domains_top .h4_main1 {
  background: url("../img/sp_business_top_jtb_img4_1_1.png") no-repeat left top;
  background-size: auto 100%; }

#domains_top .h4_main2 {
  background: url("../img/sp_business_top_jtb_img4_2_1.png") no-repeat left top;
  background-size: auto 100%; }

#domains_top .h4_main3 {
  background: url("../img/sp_business_top_jtb_img4_3_1.png") no-repeat left top;
  background-size: auto 100%; }

#domains_top .h4_main4 {
  background: url("../img/sp_business_top_jtb_img4_4_1.png") no-repeat left top;
  background-size: auto 100%; }

#domains_top .h4_img {
  position: absolute;
  margin-left: calc((40 / 750) * 100vw);
  top: calc((230 / 750) * 100vw); }
  #domains_top .h4_img img {
    width: 100%; }

#domains_top .h4_img4 {
  top: calc((330 / 750) * 100vw); }

#domains_top .domains_section:nth-of-type(odd) {
  background-color: white; }

#domains_top .domains_txt {
  font-size: calc((28 / 750) * 100vw);
  line-height: calc((52 / 750) * 100vw);
  padding-right: calc((20 / 750) * 100vw);
  padding-left: calc((20 / 750) * 100vw); }
  #domains_top .domains_txt_intro {
    padding-top: calc((263 / 750) * 100vw); }
  #domains_top .domains_txt_introfirst {
    padding-bottom: calc((73 / 750) * 100vw); }

#domains_top .domains_img1,
#domains_top .domains_img3 {
  position: relative;
  overflow-x: scroll;
  padding: 0 calc((20 / 750) * 100vw); }
  #domains_top .domains_img1 figure,
  #domains_top .domains_img3 figure {
    text-align: center; }
  #domains_top .domains_img1 .scroll_img,
  #domains_top .domains_img3 .scroll_img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: calc((375 / 750) * 100vw);
    height: calc((390 / 750) * 100vw); }

#domains_top .domains_txt_bigpicture {
  font-size: calc((28 / 750) * 100vw);
  font-weight: bold;
  padding: calc((10 / 750) * 100vw) calc((20 / 750) * 100vw);
  line-height: 1.8; }
  #domains_top .domains_txt_bigpicture strong {
    color: #cb0000; }

#domains_top .domains_img1 figure {
  margin-bottom: calc((100 / 750) * 100vw); }

#domains_top .domains_img2 img {
  padding: calc((100 / 750) * 100vw) calc((20 / 750) * 100vw);
  width: 100%; }

#domains_top .domains_img3 figure {
  margin-top: calc((70 / 750) * 100vw);
  margin-bottom: calc((60 / 750) * 100vw); }

#domains_top .domains_list_forte {
  margin-top: calc((70 / 750) * 100vw);
  padding-right: calc((20 / 750) * 100vw);
  padding-left: calc((20 / 750) * 100vw); }
  #domains_top .domains_list_forte dt, #domains_top .domains_list_forte dd {
    font-size: calc((28 / 750) * 100vw);
    line-height: calc((52 / 750) * 100vw); }
  #domains_top .domains_list_forte dt {
    font-weight: bold; }
    #domains_top .domains_list_forte dt:nth-child(1) {
      color: #0489a5; }
      #domains_top .domains_list_forte dt:nth-child(1) span {
        color: #8bc8d2; }
    #domains_top .domains_list_forte dt:nth-child(3) {
      color: #5d8b00; }
      #domains_top .domains_list_forte dt:nth-child(3) span {
        color: #acc06c; }
    #domains_top .domains_list_forte dt:nth-child(5) {
      color: #df680b; }
      #domains_top .domains_list_forte dt:nth-child(5) span {
        color: #e7a172; }
  #domains_top .domains_list_forte dd {
    margin-top: calc((13 / 750) * 100vw);
    margin-bottom: calc((46 / 750) * 100vw); }
    #domains_top .domains_list_forte dd:last-child {
      margin-bottom: 0; }

#domains_top .domains_btn {
  border: 1px solid #cc0000;
  border-radius: 50px;
  margin: calc((70 / 750) * 100vw) calc((40 / 750) * 100vw) calc((120 / 750) * 100vw); }
  #domains_top .domains_btn a {
    position: relative;
    display: block;
    padding: calc((40 / 750) * 100vw) 0;
    color: #cc0000;
    text-align: center; }
    #domains_top .domains_btn a span {
      font-size: calc((24 / 750) * 100vw); }
    #domains_top .domains_btn a::before {
      width: 10px;
      height: 1px;
      background: #cc0000; }
    #domains_top .domains_btn a::after {
      width: 4px;
      height: 4px;
      border-top: 1px solid #cc0000;
      border-right: 1px solid #cc0000;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    #domains_top .domains_btn a::before, #domains_top .domains_btn a::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 75px;
      margin: auto;
      vertical-align: middle; }
    #domains_top .domains_btn a:hover {
      text-decoration: none; }
  #domains_top .domains_btn_tourism a::before, #domains_top .domains_btn_tourism a::after {
    right: calc((180 / 750) * 100vw); }
  #domains_top .domains_btn_area a::before, #domains_top .domains_btn_area a::after {
    right: calc((95 / 750) * 100vw); }
  #domains_top .domains_btn_business a::before, #domains_top .domains_btn_business a::after {
    right: calc((80 / 750) * 100vw); }
  #domains_top .domains_btn_global a::before, #domains_top .domains_btn_global a::after {
    right: calc((120 / 750) * 100vw); }

#domains_top .domains_section_businessdetail {
  padding-bottom: calc((1 / 750) * 100vw); }

#domains_detail .section_case {
  padding-bottom: calc((100 / 750) * 100vw); }

#domains_detail .h2_txt {
  background: none; }

#domains_detail .h2_img {
  display: none; }

#domains_detail .h2_txt_global > span {
  margin-top: calc((32 / 750) * 100vw); }

#domains_detail .h3_main {
  font-size: calc((40 / 750) * 100vw);
  font-weight: bold;
  padding: calc((70 / 750) * 100vw) 0 calc((35 / 750) * 100vw);
  text-align: center; }

#domains_detail .h3_main_first {
  padding: calc((70 / 750) * 100vw) 0 calc((35 / 750) * 100vw); }

#domains_detail .h4_main {
  color: #cc0000;
  font-size: calc((28 / 750) * 100vw);
  margin: 0 0 calc((50 / 750) * 100vw);
  font-weight: bold;
  letter-spacing: 0; }

#domains_detail .h4_main1_1 {
  padding-top: calc((70 / 750) * 100vw); }

#domains_detail .h4_main1_2 {
  padding-top: calc((50 / 750) * 100vw); }

#domains_detail .h4_main1_3 {
  padding-top: calc((50 / 750) * 100vw); }

#domains_detail .h5_main {
  color: #323232;
  font-size: calc((28 / 750) * 100vw);
  font-weight: bold; }

#domains_detail .h5_main1 {
  margin-top: calc((40 / 750) * 100vw);
  margin-bottom: calc((25 / 750) * 100vw); }

#domains_detail .h5_main2,
#domains_detail .h5_main3,
#domains_detail .h5_main4,
#domains_detail .h5_main5,
#domains_detail .h5_main6,
#domains_detail .h5_main7,
#domains_detail .h5_main8,
#domains_detail .h5_main9 {
  margin-top: calc((60 / 750) * 100vw);
  margin-bottom: calc((25 / 750) * 100vw); }

#domains_detail .box {
  background: white;
  margin: 0 calc((20 / 750) * 100vw);
  padding: 0 calc((20 / 750) * 100vw); }
  #domains_detail .box_case {
    padding-top: calc((68 / 750) * 100vw);
    padding-bottom: calc((80 / 750) * 100vw); }

#domains_detail .txt {
  font-size: calc((28 / 750) * 100vw);
  line-height: calc((52 / 750) * 100vw); }
  #domains_detail .txt--small {
    font-size: calc((24 / 750) * 100vw); }

#domains_detail .txt2_2_tourism {
  padding-top: calc((40 / 750) * 100vw); }

#domains_detail .txt2_6_business {
  padding-top: calc((40 / 750) * 100vw); }

#domains_detail .img {
  margin: 0;
  padding: 0; }
  #domains_detail .img figcaption {
    text-align: center; }
  #domains_detail .img img {
    width: 100%; }

#domains_detail .img1 {
  display: block;
  margin-top: calc((54 / 750) * 100vw);
  padding-bottom: calc((70 / 750) * 100vw); }

#domains_detail .img2 {
  padding-bottom: calc((24 / 750) * 100vw); }

#domains_detail .img3 {
  padding-bottom: calc((60 / 750) * 100vw); }

#domains_detail .img4 {
  padding-bottom: calc((55 / 750) * 100vw); }

#domains_detail .img5 {
  padding-bottom: calc((52 / 750) * 100vw); }

#domains_detail .img6 {
  padding-bottom: calc((66 / 750) * 100vw); }

#domains_detail .img7 {
  padding-bottom: calc((58 / 750) * 100vw); }

#domains_detail .img8 {
  padding-bottom: calc((52 / 750) * 100vw); }

#domains_detail .img9 {
  padding-bottom: calc((52 / 750) * 100vw); }

#domains_detail .img10 {
  padding-bottom: calc((52 / 750) * 100vw); }

#domains_detail .txt_case {
  padding-top: calc((50 / 750) * 100vw);
  font-size: calc((28 / 750) * 100vw);
  font-weight: bold;
  text-align: center; }

#domains_detail .btn {
  border: 1px solid #cc0000;
  border-radius: 50px;
  margin: calc((30 / 750) * 100vw) calc((40 / 750) * 100vw) calc((120 / 750) * 100vw); }
  #domains_detail .btn a {
    position: relative;
    display: block;
    padding: calc((40 / 750) * 100vw) 0;
    color: #cc0000;
    text-align: center; }
    #domains_detail .btn a span {
      font-size: calc((24 / 750) * 100vw); }
    #domains_detail .btn a::before {
      width: 10px;
      height: 1px;
      background: #cc0000; }
    #domains_detail .btn a::after {
      width: 4px;
      height: 4px;
      border-top: 1px solid #cc0000;
      border-right: 1px solid #cc0000;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    #domains_detail .btn a::before, #domains_detail .btn a::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 75px;
      margin: auto;
      vertical-align: middle; }
    #domains_detail .btn a:hover {
      text-decoration: none; }

#domains_detail .separate_adjust_bottom {
  padding-bottom: calc((58 / 750) * 100vw);
  margin-bottom: calc((62 / 750) * 100vw);
  border-bottom: 1px dashed #dcdcdc; }

#domains_detail .externallink {
  position: relative;
  color: #323232;
  word-break: break-all;
  background: url(../img/icon_externallink.png) no-repeat center right;
  background-size: calc((30 / 750) * 100vw) calc((30 / 750) * 100vw);
  padding-right: calc((50 / 750) * 100vw); }

#domains_detail .externallink_txt {
  padding-top: calc((30 / 750) * 100vw); }

@media screen and (min-width: 750.1px) {
  .h2_box {
    position: relative;
    overflow: hidden; }
    .h2_box em, .h2_box span {
      position: relative;
      z-index: 2;
      margin-top: .5em; }
    .h2_box em {
      font-size: 36px;
      margin-top: 0; }
    .h2_box > span {
      font-size: 16px; }
    .h2_box_top {
      height: 360px;
      background: #005064;
      padding: 0; }
    .h2_box_tourism, .h2_box_business, .h2_box_area, .h2_box_global {
      background: none; }
  .h2_inner_top {
    max-width: 1160px;
    margin: 0 auto; }
  .h2_txt_top {
    position: absolute;
    width: 1650px;
    height: 360px;
    padding: 142px 0 140px 50px;
    z-index: 2; }
    .h2_txt_top em, .h2_txt_top span {
      position: relative;
      z-index: 1; }
    .h2_txt_top em {
      font-size: 36px; }
    .h2_txt_top > span {
      font-size: 16px;
      padding-top: 0; }
    .h2_txt_top::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 360px;
      background: url(../img/kv_frame_t.png) no-repeat left top;
      background-size: 100% 100%;
      top: 0;
      z-index: 1;
      left: -1000px; }
  .h2_img_top {
    width: 60%;
    height: 360px;
    position: absolute;
    top: 0;
    right: -130px;
    z-index: 1; }
    .h2_img_top::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 360px;
      background: url(../img/pc_business_top_jtb_img_ttl.jpg) no-repeat;
      background-size: cover;
      left: -130px; }
  #domains_top .h3_domains_first {
    margin-bottom: 1.217em; }
  #domains_top .h3_domains {
    margin-bottom: 1.217em; }
  #domains_top .h3_domains_first,
  #domains_top .h3_domains {
    padding: 2.917em 0 0;
    font-size: 24px; }
  #domains_top .h4_inner {
    max-width: 1160px;
    margin: 0 auto;
    height: 258px; }
    #domains_top .h4_inner::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 50%;
      height: 100%; }
    #domains_top .h4_inner_tourism::before {
      background-image: url("../img/pc_business_top_jtb_img4_1_1_pattern.png"); }
    #domains_top .h4_inner_area::before {
      background-image: url("../img/pc_business_top_jtb_img4_2_1_pattern.png"); }
    #domains_top .h4_inner_business::before {
      background-image: url("../img/pc_business_top_jtb_img4_3_1_pattern.png"); }
    #domains_top .h4_inner_global::before {
      background-image: url("../img/pc_business_top_jtb_img4_4_1_pattern.png"); }
  #domains_top .h4_main {
    position: relative;
    padding: 5.75em 0;
    margin-right: 0;
    background-position: right top; }
    #domains_top .h4_main em, #domains_top .h4_main span {
      position: relative;
      z-index: 1; }
    #domains_top .h4_main em {
      font-size: 28px;
      line-height: 1.7em; }
    #domains_top .h4_main span {
      font-size: 14px;
      margin-top: .5em; }
  #domains_top .h4_main1,
  #domains_top .h4_main2,
  #domains_top .h4_main3,
  #domains_top .h4_main4 {
    background: none; }
    #domains_top .h4_main1::before,
    #domains_top .h4_main2::before,
    #domains_top .h4_main3::before,
    #domains_top .h4_main4::before {
      position: absolute;
      content: "";
      left: -210px;
      top: 0;
      width: 790px;
      height: 258px; }
  #domains_top .h4_main1 {
    position: relative; }
    #domains_top .h4_main1::before {
      background: url("../img/pc_business_top_jtb_img4_1_1.png") no-repeat left top; }
  #domains_top .h4_main2 {
    position: relative; }
    #domains_top .h4_main2::before {
      background: url("../img/pc_business_top_jtb_img4_2_1.png") no-repeat left top; }
  #domains_top .h4_main3 {
    position: relative; }
    #domains_top .h4_main3::before {
      background: url("../img/pc_business_top_jtb_img4_3_1.png") no-repeat left top; }
  #domains_top .h4_main4 {
    position: relative; }
    #domains_top .h4_main4::before {
      background: url("../img/pc_business_top_jtb_img4_4_1.png") no-repeat left top; }
  #domains_top .h4_main4 {
    padding: 3.775em 0 3.375em; }
  #domains_top .h4_img {
    position: absolute;
    margin-left: 0;
    top: 40px;
    right: 0;
    width: 57%;
    height: 300px;
    overflow: hidden; }
    #domains_top .h4_img img {
      display: none; }
  #domains_top .h4_img1 {
    background: url("../img/pc_business_top_jtb_img4_1_2.jpg") no-repeat center;
    background-size: cover; }
  #domains_top .h4_img2 {
    background: url("../img/pc_business_top_jtb_img4_2_2.jpg") no-repeat center;
    background-size: cover; }
  #domains_top .h4_img3 {
    background: url("../img/pc_business_top_jtb_img4_3_2.jpg") no-repeat center;
    background-size: cover; }
  #domains_top .h4_img4 {
    background: url("../img/pc_business_top_jtb_img4_4_2.jpg") no-repeat center;
    background-size: cover; }
  #domains_top .h4_adjust_br {
    display: none; }
  #domains_top .domains_section_businessdetail {
    padding-bottom: 120px; }
  #domains_top .domains_txt {
    font-size: 16px;
    line-height: 2.125em;
    padding-right: 1.25em;
    padding-left: 1.25em;
    text-align: center; }
    #domains_top .domains_txt_intro {
      padding-top: 9.25em; }
    #domains_top .domains_txt_introfirst {
      padding-bottom: 4.375em; }
  #domains_top .domains_img1,
  #domains_top .domains_img3 {
    position: static;
    overflow-x: hidden; }
    #domains_top .domains_img1 .scroll_img,
    #domains_top .domains_img3 .scroll_img {
      display: none; }
  #domains_top .domains_txt_bigpicture {
    font-size: 16px;
    padding: .5em 0 3em;
    text-align: center;
    white-space: normal; }
  #domains_top .domains_img1 figure {
    margin-bottom: 67px; }
    #domains_top .domains_img1 figure img {
      width: 100%;
      max-width: 824px; }
  #domains_top .domains_img2 {
    display: block;
    width: auto;
    margin: 0 auto;
    text-align: center; }
    #domains_top .domains_img2 img {
      padding: 100px 20px;
      width: 100%;
      max-width: 827px; }
  #domains_top .domains_img3 figure {
    margin-top: 70px;
    margin-bottom: 67px;
    padding: 0 20px; }
    #domains_top .domains_img3 figure img {
      width: 100%;
      max-width: 812px; }
  #domains_top .domains_list_forte {
    width: fit-content;
    margin: 4em auto 0;
    padding-right: 0;
    padding-left: 0; }
    #domains_top .domains_list_forte dt, #domains_top .domains_list_forte dd {
      font-size: 14px;
      line-height: 2em;
      margin-top: 0;
      margin-bottom: 0; }
    #domains_top .domains_list_forte dd {
      padding-left: 1.5em; }
  #domains_top .domains_btn {
    margin: 50px auto 0;
    max-width: 420px;
    border-radius: 50px;
    transition: .2s; }
    #domains_top .domains_btn:hover {
      background: #cc0000; }
      #domains_top .domains_btn:hover a {
        color: white;
        text-decoration: none; }
        #domains_top .domains_btn:hover a::before {
          background: white; }
        #domains_top .domains_btn:hover a::after {
          border-color: white; }
    #domains_top .domains_btn a {
      padding: 1.25em 0;
      font-size: 16px; }
      #domains_top .domains_btn a span {
        font-size: 12px; }
    #domains_top .domains_btn_tourism a::before, #domains_top .domains_btn_tourism a::after {
      right: 125px; }
    #domains_top .domains_btn_tourism:hover {
      transition: .2s; }
      #domains_top .domains_btn_tourism:hover a::before, #domains_top .domains_btn_tourism:hover a::after {
        right: 120px; }
    #domains_top .domains_btn_area a::before, #domains_top .domains_btn_area a::after, #domains_top .domains_btn_business a::before, #domains_top .domains_btn_business a::after {
      right: 75px; }
    #domains_top .domains_btn_area:hover, #domains_top .domains_btn_business:hover {
      transition: .2s; }
      #domains_top .domains_btn_area:hover a::before, #domains_top .domains_btn_area:hover a::after, #domains_top .domains_btn_business:hover a::before, #domains_top .domains_btn_business:hover a::after {
        right: 70px; }
    #domains_top .domains_btn_global a::before, #domains_top .domains_btn_global a::after {
      right: 105px; }
    #domains_top .domains_btn_global:hover {
      transition: .2s; }
      #domains_top .domains_btn_global:hover a::before, #domains_top .domains_btn_global:hover a::after {
        right: 100px; }
  #domains_detail .section_case {
    padding-bottom: 91px; }
  #domains_detail .h2_inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1160px;
    margin: 0 auto;
    height: 260px;
    z-index: 1; }
    #domains_detail .h2_inner::before {
      position: absolute;
      content: "";
      left: -120px;
      top: 0;
      height: 260px;
      width: 100%; }
    #domains_detail .h2_inner_tourism::before {
      background: url("../img/pc_business_tourism_bg1.png") no-repeat;
      background-size: auto 100%; }
    #domains_detail .h2_inner_business::before {
      background: url("../img/pc_business_business_solution_jtb_bg1.png") no-repeat;
      background-size: auto 100%; }
    #domains_detail .h2_inner_area::before {
      background: url("../img/pc_business_area_solution_jtb_bg1.png") no-repeat;
      background-size: auto 100%; }
    #domains_detail .h2_inner_global::before {
      background: url("../img/pc_business_global_jtb_bg1.png") no-repeat;
      background-size: auto 100%; }
  #domains_detail .h2_box {
    position: relative;
    padding: 0; }
    #domains_detail .h2_box::before {
      position: absolute;
      content: "";
      left: 0;
      width: 100%;
      height: 260px;
      z-index: 1; }
    #domains_detail .h2_box_tourism::before {
      background: url("../img/pc_business_tourism_jtb_bg_pattern.png") repeat-x;
      background-size: auto 100%; }
    #domains_detail .h2_box_business::before {
      background: url("../img/pc_business_business_solution_jtb_bg_pattern.png") repeat-x;
      background-size: auto 100%; }
    #domains_detail .h2_box_area::before {
      background: url("../img/pc_business_area_solution_jtb_bg_pattern.png") repeat-x;
      background-size: auto 100%; }
    #domains_detail .h2_box_global::before {
      background: url("../img/pc_business_global_jtb_bg_pattern.png") repeat-x;
      background-size: auto 100%; }
  #domains_detail .h2_txt {
    position: relative;
    display: block;
    width: 50%;
    padding: 5.5em 0 5.5em 2.5em; }
    #domains_detail .h2_txt::before {
      position: absolute;
      content: "";
      right: 0;
      width: 100%;
      height: 260px;
      top: 0;
      z-index: 1; }
  #domains_detail .h2_txt_global {
    padding: 3.5em 0 3.5em 2.5em; }
    #domains_detail .h2_txt_global em span {
      font-size: 20px; }
    #domains_detail .h2_txt_global > span {
      margin-top: 1.438em; }
  #domains_detail .h2_img {
    position: absolute;
    display: block;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 1; }
    #domains_detail .h2_img::before {
      position: absolute;
      content: "";
      right: 0;
      width: 100%;
      height: 260px; }
  #domains_detail .h2_img_tourism::before {
    background: url("../img/pc_business_tourism_jtb_img_ttl2.jpg") no-repeat center;
    background-size: cover; }
  #domains_detail .h2_img_business::before {
    background: url("../img/pc_business_business_solution_jtb_img_ttl2.jpg") no-repeat center;
    background-size: cover; }
  #domains_detail .h2_img_area::before {
    background: url("../img/pc_business_area_solution_jtb_img_ttl2.jpg") no-repeat center;
    background-size: cover; }
  #domains_detail .h2_img_global::before {
    background: url("../img/pc_business_global_jtb_img_ttl2.jpg") no-repeat center;
    background-size: cover; }
  #domains_detail .h3_main {
    font-size: 24px;
    padding: 3.167em 0 1.917em; }
  #domains_detail .h3_main_first {
    padding: 2.917em 0 1.917em; }
  #domains_detail .h4_main {
    font-size: 18px;
    margin: 0 0 1.667em; }
  #domains_detail .h4_main1_1 {
    padding-top: 2.778em; }
  #domains_detail .h4_main1_2 {
    padding-top: 2.444em; }
  #domains_detail .h4_main1_3 {
    padding-top: 2.444em; }
  #domains_detail .h5_main {
    font-size: 16px; }
  #domains_detail .h5_main1,
  #domains_detail .h5_main2,
  #domains_detail .h5_main3,
  #domains_detail .h5_main4,
  #domains_detail .h5_main5,
  #domains_detail .h5_main6,
  #domains_detail .h5_main7,
  #domains_detail .h5_main8,
  #domains_detail .h5_main9 {
    margin-top: 2.125em;
    margin-bottom: 1em; }
  #domains_detail .box {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 50px; }
    #domains_detail .box_case {
      padding-top: 50px;
      padding-bottom: 57px; }
  #domains_detail .txt {
    font-size: 16px;
    line-height: 2em;
    letter-spacing: 0; }
    #domains_detail .txt--small {
      font-size: 14px; }
  #domains_detail .img1 {
    display: block;
    margin: 40px auto 0;
    padding-bottom: 50px;
    text-align: center; }
    #domains_detail .img1 img {
      max-width: 550px; }
  #domains_detail .tourism_img1 img {
    max-width: 430px; }
  #domains_detail .business_img1 img {
    max-width: 500px; }
  #domains_detail .area_img1 img {
    max-width: 670px; }
  #domains_detail .global_img1 img {
    max-width: 710px; }
  #domains_detail .img2,
  #domains_detail .img3,
  #domains_detail .img4,
  #domains_detail .img5,
  #domains_detail .img6,
  #domains_detail .img7,
  #domains_detail .img8,
  #domains_detail .img9,
  #domains_detail .img10 {
    width: 405px;
    margin-right: 50px;
    padding-bottom: 0; }
  #domains_detail .img7 {
    padding-bottom: 0; }
  #domains_detail .layout_separate {
    display: flex;
    align-items: flex-start; }
  #domains_detail .txt_separate {
    max-width: 405px; }
  #domains_detail .txt2_2_tourism {
    padding-top: 1.625em; }
  #domains_detail .txt2_6_business {
    padding-top: 1.625em; }
  #domains_detail .separate_adjust_bottom {
    padding-bottom: 35px;
    margin-bottom: 35px; }
  #domains_detail .txt_case {
    padding-top: 3em;
    font-size: 16px; }
  #domains_detail .btn {
    margin: 30px auto 0;
    max-width: 420px;
    border-radius: 50px;
    transition: .2s; }
    #domains_detail .btn:hover {
      background: #cc0000; }
      #domains_detail .btn:hover a {
        color: white;
        text-decoration: none; }
        #domains_detail .btn:hover a::before {
          background: white; }
        #domains_detail .btn:hover a::after {
          border-color: white; }
    #domains_detail .btn a {
      padding: 1.25em 0;
      font-size: 16px; }
      #domains_detail .btn a span {
        font-size: 12px; }
  #domains_detail .externallink {
    background-size: 1em 1.125em;
    padding-right: 1.875em; }
  #domains_detail .externallink_txt {
    padding-top: .8em; } }
