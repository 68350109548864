@charset "utf-8";

// -------------------- [ color ]
$defcolor: rgba(50,50,50,1);  // #323232
$black: rgba(0,0,0,1);
$white: rgba(255,255,255,1);
$red: rgba(183, 0, 0, 1); //赤、記事詳細内見出し
$gray1: rgba(200, 200, 200, 1); //灰色、リストの｜
$gray2: rgba(150, 150, 150, 1); //灰色、selectの文字色

$ico_color_program: #f5a262;
$ico_color_report: #4da9c0;

// -------------------- [ height ]
$height_header: 103px;


// -------------------- [ z-index ]
$zindex: (
    load: 10000,  // ロード
    modal: 5000,  // モーダル
    header: 2000,  // ヘッダー
    totop: 1800,  // トップアンカ
    footer: 1700  // フッター
);

