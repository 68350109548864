@charset "utf-8";

@import '../../../common/css/function';

.elm_effect {
    opacity: 0;
    transform : translate(0, 50px);
    transition : all 500ms;
    &.active {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.h2_box {
    em,span {
        color: $white;
        display: block;
        font-weight: normal;
    }
    padding: spvw(113) spvw(40);
    em {
        font-size: spvw(52);
        font-family: 'Open Sans', sans-serif;
        span {
            font-size: spvw(36);
        }
    }
    > span {
        font-size: spvw(28);
    }
    &_top {
        background-image: url("../img/sp_business_top_jtb_bg1.png"); 
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
    }
    &_tourism {
        background: url("../img/sp_business_tourism_jtb_bg.png") no-repeat left top;
        background-size: cover;
    }
    &_business {
        background: url("../img/sp_business_business_solution_bg.png") no-repeat left top;
        background-size: cover;
    }
    &_area {
        background: url("../img/sp_business_area_solution_jtb_bg.png") no-repeat left top;
        background-size: cover;
    }
    &_global {
        background: url("../img/sp_business_global_jtb_bg.png") no-repeat left top;
        background-size: cover;
    }
}
.h2_txt_top {
    em,span {
        color: $white;
        display: block;
        font-weight: normal;
    }
    // padding: spvw(113) spvw(40);
    em {
        font-size: spvw(52);
        span {
            font-size: spvw(36);
        }
    }
    > span {
        font-size: spvw(28);
        padding-top: spvw(10);
    }
}

#domains_top {
    .h3_domains_first {
        padding: spvw(70) 0 0;
    }
    .h3_domains {
        padding: spvw(100) 0 0;
    }
    .h3_domains_first,
    .h3_domains {
        font-size: spvw(40);
        text-align: center;
        font-weight: bold;
        margin-bottom: spvw(40);
    }
    .h4_box {
        position: relative;
    }
    .h4_main {
        padding: spvw(70) 0 spvw(200) spvw(40);
        margin-right: spvw(70);
        em,span {
            color: $white;
            display: block;
        }
        em {
            font-size: spvw(40);
            font-weight: bold;
            line-height: 1.6;
        }
        span {
            font-size: spvw(26);
            font-family: 'Open Sans', sans-serif;
            margin-top: spvw(10);
        }
    }
    .h4_main1 {
        background: url("../img/sp_business_top_jtb_img4_1_1.png") no-repeat left top;
        background-size: auto 100%;
    }
    .h4_main2 {
        background: url("../img/sp_business_top_jtb_img4_2_1.png") no-repeat left top;
        background-size: auto 100%;
    }
    .h4_main3 {
        background: url("../img/sp_business_top_jtb_img4_3_1.png") no-repeat left top;
        background-size: auto 100%;
    }
    .h4_main4 {
        background: url("../img/sp_business_top_jtb_img4_4_1.png") no-repeat left top;
        background-size: auto 100%;
    }
    .h4_img {
        position: absolute;
        margin-left: spvw(40);
        top: spvw(230);  
        img {
            width: 100%;
        }    
    }
    .h4_img4 {
        top: spvw(330);
        // top: spvw(345);
    }
    .domains_section {
        &:nth-of-type(odd) {
            background-color: $white;
        }
    }
    .domains_txt {
        font-size: spvw(28);
        line-height: spvw(52);
        padding-right: spvw(20);
        padding-left: spvw(20);
        &_intro {
            // padding-top: spvw(180);
            padding-top: spvw(263);
        }
        &_introfirst {
            padding-bottom: spvw(73);
        }
    }
    .domains_img1,
    .domains_img3 {
        position: relative;
        overflow-x: scroll;
        padding: 0 spvw(20);
        figure {
            text-align: center;
        }
        .scroll_img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: spvw(375);
            height: spvw(390);
        }
    }

    .domains_txt_bigpicture {
        font-size: spvw(28);
        font-weight: bold;
        // padding: spvw(70) spvw(20);
        padding: spvw(10) spvw(20);
        line-height: 1.8;
        strong {
            color: #cb0000;
        }
     }

    .domains_img1 {
        figure {
            margin-bottom: spvw(100);
        }
    }
    .domains_img2 {
        // background: url("../img/sp_business_top_jtb_img2.png") no-repeat center;
        // background-size: contain;
        // margin: spvw(80) spvw(40) spvw(100);
        img {
            padding: spvw(100) spvw(20);
            width: 100%;
        }
    }
    // .domains_img2_inner1 {
    //     display: flex;
    //     justify-content: center;
    //     text-align: center;
    //     padding-top: spvw(205);
    //     gap: 0 1%;
    //     a {
    //         display: block;
    //         width: 30%;
    //         height: spvw(335);
    //         font-size: 0;
    //     }
    // }
    // .domains_img2_inner2 {
    //     width: 100%;
    //     margin: 0 auto;
    //     text-align: center;
    //     padding-bottom: spvw(40);
    //     a {
    //         display: block;
    //         width: 95%;
    //         height: spvw(110);
    //         margin: 0 auto;
    //         font-size: 0;
    //     }
    // }
    .domains_img3 {
        figure {
            margin-top: spvw(70);
            margin-bottom: spvw(60);
        }
    }
    .domains_list_forte {
        margin-top: spvw(70);
        padding-right: spvw(20);
        padding-left: spvw(20);
        dt,dd {
            font-size: spvw(28);
            line-height: spvw(52);
        }
        dt {
            font-weight: bold;
            &:nth-child(1) {
                color: #0489a5;
                span {
                    color: #8bc8d2;
                }
            }
            &:nth-child(3) {
                color: #5d8b00;
                span {
                    color: #acc06c;
                }
            }
            &:nth-child(5) {
                color: #df680b;
                span {
                    color: #e7a172;
                }
            }
        }
        dd {
            margin-top: spvw(13);
            margin-bottom: spvw(46);
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .domains_btn {
        border: 1px solid #cc0000;
        border-radius: 50px;
        margin: spvw(70) spvw(40) spvw(120);
        a {
            position: relative;
            display: block;
            padding: spvw(40) 0;
            color: #cc0000;
            text-align: center;
            span {
                font-size: spvw(24);
            }
            &::before {
                width: 10px;
                height: 1px;
                background: #cc0000;
            }
            &::after {
                width: 4px;
                height: 4px;
                border-top: 1px solid #cc0000;
                border-right: 1px solid #cc0000;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 75px;
                margin: auto;
                vertical-align: middle;
            }
            &:hover {
                text-decoration: none;
            }
        }
        &_tourism {
            a {
                &::before,
                &::after {
                    right: spvw(180);
                }
            }
        }
        &_area {
            a {
                &::before,
                &::after {
                    right: spvw(95);
                }
            }
        }
        &_business {
            a {
                &::before,
                &::after {
                    right: spvw(80);
                }
            }
        }
        &_global {
            a {
                &::before,
                &::after {
                    right: spvw(120);
                }
            }
        }
    }
    .domains_section_businessdetail {
        padding-bottom: spvw(1);
    }
}
#domains_detail {
    .section_case {
        padding-bottom: spvw(100);
    }
    .h2_txt {
        background: none;
    }
    .h2_img {
        display: none;
    }
    .h2_txt_global {
        > span {
            margin-top: spvw(32);
        }
    }
    .h3_main {
        font-size: spvw(40);
        font-weight: bold;
        padding: spvw(70) 0 spvw(35);
        text-align: center;
    }
    .h3_main_first {
        padding: spvw(70) 0 spvw(35);
    }
    .h4_main {
        color: #cc0000;
        font-size: spvw(28);
        margin: 0 0 spvw(50);
        font-weight: bold;
        letter-spacing: 0;
    }
    .h4_main1_1 {
        padding-top: spvw(70);
    }
    .h4_main1_2 {
        padding-top: spvw(50);
    }
    .h4_main1_3 {
        padding-top: spvw(50);
    }
    .h5_main {
        color: #323232;
        font-size: spvw(28);
        font-weight: bold;
    }
    .h5_main1 {
        margin-top: spvw(40);
        margin-bottom: spvw(25);
    }
    .h5_main2,
    .h5_main3,
    .h5_main4,
    .h5_main5,
    .h5_main6,
    .h5_main7,
    .h5_main8,
    .h5_main9 {
        margin-top: spvw(60);
        margin-bottom: spvw(25);
    }
    // .h5_main3 {
    //     margin-top: spvw(60);
    //     margin-bottom: spvw(25);
    // }
    // .h5_main4 {
    //     margin-top: spvw(60);
    //     margin-bottom: spvw(25);
    // }
    // .h5_main5 {
    //     margin-top: spvw(60);
    //     margin-bottom: spvw(25);
    // }
    // .h5_main6 {
    //     margin-top: spvw(60);
    //     margin-bottom: spvw(25);        
    // }
    // .h5_main7 {
    //     margin-top: spvw(80);
    //     margin-bottom: spvw(50);        
    // }
    // .h5_main8 {
    //     margin-top: spvw(80);
    //     margin-bottom: spvw(50);        
    // }
    // .h5_main9 {
    //     margin-top: spvw(80);
    //     margin-bottom: spvw(50);        
    // }

    .box {
        background: $white;
        margin: 0 spvw(20);
        padding: 0 spvw(20);
        &_case {
            padding-top: spvw(68);
            padding-bottom: spvw(80);
        }
    }
    .txt {
        font-size: spvw(28);
        line-height: spvw(52);
        &--small{
          font-size: spvw(24);
        }
    }
    .txt2_2,
    .txt2_4,
    .txt2_6,
    .txt2_7 {
        // padding-top: spvw(20);
    }
    .txt2_2_tourism {
        padding-top: spvw(40);
    }
    .txt2_6_business {
        padding-top: spvw(40);
    }
    .img {
        margin: 0;
        padding: 0;
        figcaption {
            text-align: center;
        }
        img {
            width: 100%;
        }
    }
    .img1 {
        display: block;
        margin-top: spvw(54);
        padding-bottom: spvw(70);
    }
    .img2 {
        padding-bottom: spvw(24);
    }
    .img3 {
        padding-bottom: spvw(60);
    }
    .img4 {
        padding-bottom: spvw(55);
    }
    .img5 {
        padding-bottom: spvw(52);
    }
    .img6 {
        padding-bottom: spvw(66);
    }
    .img7 {
        padding-bottom: spvw(58);
    }
    .img8 {
        padding-bottom: spvw(52);
    }
    .img9 {
        padding-bottom: spvw(52);
    }
    .img10 {
        padding-bottom: spvw(52);
    }
    .txt_case {
        padding-top: spvw(50);
        font-size: spvw(28);
        font-weight: bold;
        text-align: center;
        // em {
        //     font-size: spvw(28);
        // }
    }
    .btn {
        border: 1px solid #cc0000;
        border-radius: 50px;
        margin: spvw(30) spvw(40) spvw(120);
        a {
            position: relative;
            display: block;
            padding: spvw(40) 0;
            color: #cc0000;
            text-align: center;
            span {
                font-size: spvw(24);
            }
            &::before {
                width: 10px;
                height: 1px;
                background: #cc0000;
            }
            &::after {
                width: 4px;
                height: 4px;
                border-top: 1px solid #cc0000;
                border-right: 1px solid #cc0000;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 75px;
                margin: auto;
                vertical-align: middle;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    .separate_adjust_bottom {
        padding-bottom: spvw(58);
        margin-bottom: spvw(62);
        border-bottom: 1px dashed #dcdcdc;
    }
    .externallink {
        position: relative;
        color: #323232;
        word-break: break-all;
        background: url(../img/icon_externallink.png) no-repeat center right;
        background-size: spvw(30) spvw(30);
        padding-right: spvw(50);
    }
    .externallink_txt {
        padding-top: spvw(30);
    }
}

@include mq(pc) {
    .h2_box {
        position: relative;
        overflow: hidden;
        em,span {
            position: relative;
            z-index: 2;
            margin-top: .5em;
        }
        em {
            font-size: 36px;
            margin-top: 0;
        }
        > span {
            font-size: 16px;
        }
        &_top {
            height: 360px;
            // background-image: linear-gradient(90deg, rgba(0,81,102,1) 0%, rgba(0,123,153,1) 40%);
            // background-size: 100%;
            background: #005064;
            padding: 0;
        }
        &_tourism,
        &_business,
        &_area,
        &_global {
            background: none;
        }
    }
    .h2_inner_top {
        max-width: 1160px;
        margin: 0 auto;
    }
    .h2_txt_top {
        position: absolute;
        width: 1650px;
        height: 360px;
        padding: 142px 0 140px 50px;
        z-index: 2;
        em,span {
            position: relative;
            z-index: 1;
        }
        em {
            font-size: 36px;
        }
        > span {
            font-size: 16px;
            padding-top: 0;
        }
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 360px;
            background: url(../img/kv_frame_t.png) no-repeat left top;
            background-size: 100% 100%;
            top: 0;
            z-index: 1;
            left: -1000px;          
        }
    }
    .h2_img_top {
        width: 60%;
        height: 360px;
        position: absolute;
        top: 0;
        right: -130px;
        z-index: 1;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 360px;
            background: url(../img/pc_business_top_jtb_img_ttl.jpg) no-repeat;
            background-size: cover;
            left: -130px;
        }
    }
    #domains_top {
        .h3_domains_first {
            margin-bottom: 1.217em;
        }
        .h3_domains {
            margin-bottom: 1.217em;
        }
        .h3_domains_first,
        .h3_domains {
            padding: 2.917em 0 0;
            font-size: 24px;
        }
        .h4_inner {
            max-width: 1160px;
            margin: 0 auto;
            height: 258px;
            &::before {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 50%;
                height: 100%;
            }
            &_tourism {
                &::before {
                    background-image: url("../img/pc_business_top_jtb_img4_1_1_pattern.png");
                }
            }
            &_area {
                &::before {
                    background-image: url("../img/pc_business_top_jtb_img4_2_1_pattern.png");
                }
            }
            &_business {
                &::before {
                    background-image: url("../img/pc_business_top_jtb_img4_3_1_pattern.png");
                }
            }
            &_global {
                &::before {
                    background-image: url("../img/pc_business_top_jtb_img4_4_1_pattern.png");
                }
            }
        }
        .h4_main {
            position: relative;
            // width: 50%;
            // padding: 3.571em 0 3.571em 4.286em;
            padding: 5.75em 0;
            margin-right: 0;
            background-position: right top;
            em,span {
                position: relative;
                z-index: 1;
            }
            em {
                font-size: 28px;
                line-height: 1.7em;
            }
            span {
                font-size: 14px;
                margin-top: .5em;
            }
        }
        .h4_main1,
        .h4_main2,
        .h4_main3,
        .h4_main4 {
            background: none;
            &::before {
                position: absolute;
                content: "";
                left: -210px;
                top: 0;
                width: 790px;
                height: 258px;
            }
        }
        .h4_main1 {
            position: relative;
            &::before {
                background: url("../img/pc_business_top_jtb_img4_1_1.png") no-repeat left top;
            }
        }
        .h4_main2 {
            position: relative;
            &::before {
                background: url("../img/pc_business_top_jtb_img4_2_1.png") no-repeat left top;
            }
        }
        .h4_main3 {
            position: relative;
            &::before {
                background: url("../img/pc_business_top_jtb_img4_3_1.png") no-repeat left top;
            }
        }
        .h4_main4 {
            position: relative;
            &::before {
                background: url("../img/pc_business_top_jtb_img4_4_1.png") no-repeat left top;
            }
        }
        .h4_main4 {
            // padding: 5em 0 3.375em;
            padding: 3.775em 0 3.375em;
        }
        .h4_img {
            position: absolute;
            margin-left: 0;
            top: 40px;
            right: 0;
            width: 57%;
            height: 300px;
            overflow: hidden;
            img {
                display: none;
            }
        }
        .h4_img1 {
            background: url("../img/pc_business_top_jtb_img4_1_2.jpg") no-repeat center;
            background-size: cover;
        }
        .h4_img2 {
            background: url("../img/pc_business_top_jtb_img4_2_2.jpg") no-repeat center;
            background-size: cover;
        }
        .h4_img3 {
            background: url("../img/pc_business_top_jtb_img4_3_2.jpg") no-repeat center;
            background-size: cover;
        }
        .h4_img4 {
            background: url("../img/pc_business_top_jtb_img4_4_2.jpg") no-repeat center;
            background-size: cover;
        }
        .h4_adjust_br {
            display: none;
        }
        .domains_section_businessdetail {
            padding-bottom: 120px;
        }
        .domains_txt {
            font-size: 16px;
            line-height: 2.125em;
            padding-right: 1.25em;
            padding-left: 1.25em;
            text-align: center;
            &_intro {
                padding-top: 9.25em;
            }
            &_introfirst {
                padding-bottom: 4.375em;
            }
        }
        .domains_img1,
        .domains_img3 {
            position: static;
            overflow-x: hidden;
            .scroll_img {
                display: none;
            }
        }

        .domains_txt_bigpicture {
            font-size: 16px;
            // padding: 4em 0 3em;
            padding: .5em 0 3em;
            text-align: center;
            white-space: normal;            
        }

        .domains_img1 {
            figure {
                margin-bottom: 67px;
                img {
                    width: 100%;
                    max-width: 824px;
                }
            }
        }
        .domains_img2 {
            display: block;
            width: auto;
            margin: 0 auto;
            text-align: center;
            img {
                padding: 100px 20px;
                width: 100%;
                max-width: 827px;
            }
        }
        // .domains_img2 {
        //     width: auto;
        //     height: 617px;
        //     background: url("../img/pc_business_top_jtb_img2.png") no-repeat center;
        //     background-size: contain;
        //     margin: 72px auto 80px;
        // }
        // .domains_img2_inner1 {
        //     padding-top: 171px;
        //     gap: 0 2.2%;
        //     a {
        //         width: 213px;
        //         height: 282px;
        //     }
        // }
        // .domains_img2_inner2 {
        //     max-width: 745px;
        //     padding-bottom: mdvw(29,750.1);
        //     a {
        //         width: 100%;
        //         height: 115px;
        //     }
        // }
        .domains_img3 {
            figure {
                margin-top: 70px;
                margin-bottom: 67px;
                padding: 0 20px;
                img {
                    width: 100%;
                    max-width: 812px;
                }
            }
        }
        .domains_list_forte {
            // display: flex;
            // flex-wrap: wrap;
            // align-items: stretch;
            // width: 45em;
            width: fit-content;
            margin: 4em auto 0;
            padding-right: 0;
            padding-left: 0;
            dt,dd {
                font-size: 14px;
                line-height: 2em;
                margin-top: 0;
                // margin-bottom: 2.143em;
                margin-bottom: 0;
            }
            dt {
                // width: 11.429em;
                // text-indent: -1.4em;
            }
            dd {
                // width: calc(100% - 11.429em);
                // padding-left: 2.143em;
                padding-left: 1.5em;
            }
        }
        .domains_btn {
            margin: 50px auto 0;
            max-width: 420px;
            border-radius: 50px;
            transition: .2s;
            &:hover {
                background: #cc0000;
                a {
                    color: $white;
                    text-decoration: none;
                    &::before {
                        background: $white;
                    }
                    &::after {
                        border-color: $white;
                    }
                }
            }
            a {
                padding: 1.25em 0;
                font-size: 16px;
                span {
                    font-size: 12px;
                }
            }
            &_tourism {
                a {
                    &::before,
                    &::after {
                        right: 125px;
                    }
                }
                &:hover {
                    transition: .2s;
                    a {
                        &::before,
                        &::after {
                            right: 120px;
                        }
                    }
                }
            }
            &_area,
            &_business {
                a {
                    &::before,
                    &::after {
                        right: 75px;
                    }
                }
                &:hover {
                    transition: .2s;
                    a {
                        &::before,
                        &::after {
                            right: 70px;
                        }
                    }
                }
            }
            &_global {
                a {
                    &::before,
                    &::after {
                        right: 105px;
                    }
                }
                &:hover {
                    transition: .2s;
                    a {
                        &::before,
                        &::after {
                            right: 100px;
                        }
                    }
                }
            }
        }
    }
    #domains_detail {
        .section_case {
            padding-bottom: 91px;
        }
        .h2_inner {
            position: relative;
            display: flex;
            justify-content: space-between;
            max-width: 1160px;
            margin: 0 auto;
            height: 260px;
            z-index: 1;
            &::before {
                position: absolute;
                content: "";
                left: -120px;
                top: 0;
                height: 260px;
                width: 100%;
            }
            &_tourism {
                &::before {
                    background: url("../img/pc_business_tourism_bg1.png") no-repeat;
                    background-size: auto 100%;
                }
            }
            &_business {
                &::before {
                    background: url("../img/pc_business_business_solution_jtb_bg1.png") no-repeat;
                    background-size: auto 100%;
                }
            }
            &_area {
                &::before {
                    background: url("../img/pc_business_area_solution_jtb_bg1.png") no-repeat;
                    background-size: auto 100%;
                }
            }
            &_global {
                &::before {
                    background: url("../img/pc_business_global_jtb_bg1.png") no-repeat;
                    background-size: auto 100%;
                }
            }
        }
        .h2_box {
            position: relative;
            padding: 0;
            &::before {
                position: absolute;
                content: "";
                left: 0;
                width: 100%;  
                height: 260px;  
                z-index: 1;            
            }
            &_tourism {
                &::before {
                    background: url("../img/pc_business_tourism_jtb_bg_pattern.png") repeat-x;
                    background-size: auto 100%;
                }
            }
            &_business {
                &::before {
                    background: url("../img/pc_business_business_solution_jtb_bg_pattern.png") repeat-x;
                    background-size: auto 100%;
                }
            }
            &_area {
                &::before {
                    background: url("../img/pc_business_area_solution_jtb_bg_pattern.png") repeat-x;
                    background-size: auto 100%;
                }
            }
            &_global {
                &::before {
                    background: url("../img/pc_business_global_jtb_bg_pattern.png") repeat-x;
                    background-size: auto 100%;
                }
            }
        }
        .h2_txt {
            position: relative;
            display: block;
            width: 50%;
            // padding: 98px 0 99px 160px;
            padding: 5.5em 0 5.5em 2.5em;
            &::before {
                position: absolute;
                content: "";
                right: 0;
                width: 100%;
                height: 260px;
                top: 0;
                z-index: 1;
            }
        }
        // .h2_txt_tourism {
        //     padding: 5.5em 0 5.5em 2.5em;
        // }
        // .h2_txt_business {
        //     padding: 5.5em 0 5.5em 2.5em;
        // }
        // .h2_txt_area {
        //     padding: 5.5em 0 5.5em 2.5em;
        // }
        .h2_txt_global {
            padding: 3.5em 0 3.5em 2.5em;
            em {
                span {
                    font-size: 20px;
                }
            }
            > span {
                margin-top: 1.438em;
            }
        }
        .h2_img {
            position: absolute;
            display: block;
            width: 50%;
            top: 0;
            right: 0;
            z-index: 1;
            &::before {
                position: absolute;
                content: "";
                right: 0;
                width: 100%;
                height: 260px;
            }
        }
        .h2_img_tourism {
            &::before {
                background: url("../img/pc_business_tourism_jtb_img_ttl2.jpg") no-repeat center;
                background-size: cover;
            }
        }
        .h2_img_business {
            &::before {
                background: url("../img/pc_business_business_solution_jtb_img_ttl2.jpg") no-repeat center;
                background-size: cover;
            }
        }
        .h2_img_area {
            &::before {
                background: url("../img/pc_business_area_solution_jtb_img_ttl2.jpg") no-repeat center;
                background-size: cover;
            }
        }
        .h2_img_global {
            &::before {
                background: url("../img/pc_business_global_jtb_img_ttl2.jpg") no-repeat center;
                background-size: cover;
            }
        }
        .h3_main {
            font-size: 24px;
            // padding: 4.167em 0 2.917em;
            padding: 3.167em 0 1.917em;
        }
        .h3_main_first {
            padding: 2.917em 0 1.917em;
        }
        .h4_main {
            font-size: 18px;
            margin: 0 0 1.667em;
        }
        .h4_main1_1 {
            padding-top: 2.778em;
        }
        .h4_main1_2 {
            padding-top: 2.444em;
        }
        .h4_main1_3 {
            padding-top: 2.444em;
        }
        .h5_main {
            font-size: 16px;
        }
        // .h5_main1 {
            // margin-top: 3.125em;
            // margin-bottom: 1.875em;
        //     margin-top: 2.125em;
        //     margin-bottom: 1em;
        // }
        // .h5_main2 {
            // margin-top: 5em;
            // margin-bottom: 2.313em;
            // margin-top: 3.125em;
        //     margin-top: 2.125em;
        //     margin-bottom: 1em;
        // }
        .h5_main1,
        .h5_main2,        
        .h5_main3,
        .h5_main4,
        .h5_main5,
        .h5_main6,
        .h5_main7,
        .h5_main8,
        .h5_main9 {
            // margin-top: 3.125em;
            // margin-bottom: 1.875em;
            margin-top: 2.125em;
            margin-bottom: 1em;
        }
        .box {
            max-width: 960px;
            margin: 0 auto;
            padding: 0 50px;
            &_case {
                padding-top: 50px;
                padding-bottom: 57px;
            }
        }   
        .txt {
            font-size: 16px;
            line-height: 2em;
            letter-spacing: 0;
            &--small{
              font-size: 14px;
            }
        }
        .img1 {
            display: block;
            margin: 40px auto 0;
            padding-bottom: 50px;
            text-align: center;
            img {
                max-width: 550px;
            }
        }
        .tourism_img1 {
            img {
                max-width: 430px;
            }
        }
        .business_img1 {
            img {
                max-width: 500px;
            }
        }
        .area_img1 {
            img {
                max-width: 670px;
            }
        }
        .global_img1 {
            img {
                max-width: 710px;
            }
        }
        .img2,
        .img3,
        .img4,
        .img5,
        .img6,
        .img7,
        .img8,
        .img9,
        .img10 {
            width: 405px;
            margin-right: 50px;
            padding-bottom: 0;
        }
        .img7 {
            padding-bottom: 0;
        }
        .layout_separate {
            display: flex;
            align-items: flex-start;
        }
        .txt_separate {
            max-width: 405px;
        }
        .txt2_2,
        .txt2_4,
        .txt2_6,
        .txt2_7 {
            // padding-top: 1.625em;
            // padding-top: .8em;
        }
        .txt2_2_tourism {
            padding-top: 1.625em;           
        }
        .txt2_6_business {
            padding-top: 1.625em;
        }
        .separate_adjust_bottom {
            // padding-bottom: 66px;
            // margin-bottom: 60px;
            padding-bottom: 35px;
            margin-bottom: 35px;
        }
        .txt_case {
            padding-top: 3em;
            font-size: 16px;
            // em {
            //     font-size: 18px;
            // }
        }
        .btn {
            margin: 30px auto 0;
            max-width: 420px;
            border-radius: 50px;
            transition: .2s;
            &:hover {
                background: #cc0000;
                a {
                    color: $white;
                    text-decoration: none;
                    &::before {
                        background: $white;
                    }
                    &::after {
                        border-color: $white;
                    }
                }
            }
            a {
                padding: 1.25em 0;
                font-size: 16px;
                span {
                    font-size: 12px;
                }
            }
        }
        .externallink {
            background-size: 1em 1.125em;
            padding-right: 1.875em;
        }
        .externallink_txt {
            // padding-top: 1.563em;
            padding-top: .8em;
        }
    }
}
